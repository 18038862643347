import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Image from 'react-bootstrap/Image';
import { Table, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { deleteFromCart } from '../../actions/actions';

import './CheckoutItemTable.css';

function CheckoutItemTable({ cartItems, deleteFromCart }) {
  const [reloadTable, setReloadTable] = useState(false);
  const [itemRemoved, setItemRemoved] = useState(false);
  const navigate = useNavigate();

  // Handles removing an item from the cart
  const handleRemoveItem = (itemId) => {
    deleteFromCart(itemId);
    setItemRemoved(true); // Trigger re-render after item removal
  };

  // Trigger table reload after an item is removed
  useEffect(() => {
    if (itemRemoved) {
      setReloadTable((prev) => !prev);
      setItemRemoved(false); // Reset itemRemoved state
    }
  }, [itemRemoved]);

  return (
    <Table striped bordered hover key={reloadTable} className="checkout-item-table">
      <thead>
        <tr>
          <th className="checkout-wider-column">Product</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {cartItems.map((item, index) => (
          <tr key={index}>
            <td className="checkout-wider-column">
              <Image
                className="checkout-cart-item-thumbnail"
                thumbnail
                src={item.product.images[0]}
                alt={item.product.name}
              />
              {item.product.name}
            </td>
            <td className="checkout-table-quantity">
              {item.quantity} {/* Simply show the quantity */}
            </td>
            <td className="checkout-table-price">€{(item.quantity * item.product.price).toFixed(2)}</td>
            <td className="checkout-table-actions">
              <Button
                variant="primary"
                size="sm"
                onClick={() => navigate('/eshop/cart')}
              >
                Edit
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveItem(item.product.id)}
                style={{ marginRight: '10px' }}
              >
                Remove
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
});

const mapDispatchToProps = {
  deleteFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItemTable);
