import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Figure } from 'react-bootstrap';
import countries from '../../data/countries.json';
import axios from 'axios';
import { resetCart } from '../../actions/actions';
import CheckoutItemTable from '../../components/eshop/CheckoutItemTable';

import "./Checkout.css";

function Checkout({ cartItems, orderUUID, resetCart }) {
  const navigate = useNavigate();
  const { orderUUID: urlOrderUUID } = useParams();

  const currentOrderUUID = orderUUID || urlOrderUUID;

  useEffect(() => {
    if (!currentOrderUUID) {
      alert('No orderUUID found. Redirecting...');
      navigate('/'); // Redirect to home or cart page
    }
  }, [currentOrderUUID, navigate]);

  const calculateTotalAmount = () => {
    if (cartItems && cartItems.length > 0) {
      return cartItems.reduce((total, item) => {
        return total + item.product.price * item.quantity;
      }, 0);
    }
    return 0;
  };

  const calculateVAT = (amount) => {
    const VAT_RATE = 0.24; // Assuming VAT is 24%
    return amount * VAT_RATE;
  };

  const calculateShippingCost = (shippingOption) => {
    if (shippingOption === 'ELTA') {
      return 4.35;
    }
    if (shippingOption === 'FedEx') {
      return 25.0;
    }
    return 0;
  };


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    addressLine: '',
    city: '',
    province: '',
    zip: '',
    country: 'Greece',
    shippingOption: 'ELTA',
    orderType: 'receipt', // Added orderType field, default is 'receipt'
    vatNumber: '',
    companyName: '',
    companyAddress: '',
    companyCity: '',
    companyZip: '',
    occupation: '',
    taxOffice: '',
    agreedToTerms: false, // Added for Terms and Conditions checkbox
  });

  const totalAmount = calculateTotalAmount();
  const vatAmount = calculateVAT(totalAmount);
  const shippingAmount = calculateShippingCost(formData.shippingOption);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'country') {
      const newShippingOption = value === 'Greece' ? 'ELTA' : 'FedEx';
      setFormData({
        ...formData,
        [name]: value,
        shippingOption: newShippingOption,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleShippingOptionChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      shippingOption: value,
    });
  };

  const handleOrderTypeChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      orderType: value,
    });
  };

  const handleTermsChange = (e) => {
    setFormData({
      ...formData,
      agreedToTerms: e.target.checked,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = ['fullName', 'email', 'phoneNumber', 'country', 'shippingOption'];
    if (formData.shippingOption !== 'Box Now') {
      requiredFields.push('addressLine', 'city', 'province', 'zip');
    }

    const isAllFieldsFilled = requiredFields.every((field) => formData[field]);
    if (!isAllFieldsFilled) {
      alert('Please fill in all required fields.');
      return;
    }

    if (!formData.agreedToTerms) {
      alert('Please agree to the Terms and Conditions.');
      return;
    }

    const itemDetails = cartItems.map(item => ({
      name: item.product.name,
      quantity: item.quantity,
    }));

    const finalData = {
      ...formData,
      totalAmount: totalAmount , // Total amount WITHOUT Shipping
      items: itemDetails, // Add item names and quantities
    };

    try {
      const orderResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/orders/createNewOrder`,
        finalData
      );

      const { orderCode } = orderResponse.data;
      const vivaOrderCode = orderCode?.vivaOrder?.orderCode;

      if (vivaOrderCode) {
        resetCart();
        const redirectUrl = `${process.env.REACT_APP_VIVA_BASE_URL}/web/checkout?ref=${vivaOrderCode}&color=${process.env.REACT_APP_VIVA_COLOR}&paymentMethod=${process.env.REACT_APP_DEFAULT_PAYMENT_METHOD}`;
        window.location.replace(redirectUrl);
      } else {
        alert('Order created but no valid payment order code received.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit the order. Please try again.');
    }
  };

  return (
    <Container className="checkout-cont">
      <Form onSubmit={handleSubmit}> {/* Single Form wraps everything */}
        <Row>
          <Col className='checkout-left-cont'>
            <h1 className="checkout-title">Checkout</h1>
  
            {/* Left Section - Order Details */}
            <h3 className='checkout-order-title'>Your Order</h3>
            <Row>
              <Col md={4} className="checkout-right">
                <CheckoutItemTable />
              </Col>
              <Col md={8} className="checkout-left">
                <h3 className='checkout-order-title'>Delivery</h3>
  
                {/* Order Type Selection */}
                <Form.Group controlId="orderType" className='order-type-select'>
                  <Form.Label>Please Select Receipt or Invoice</Form.Label>
                  <Row>
                    <Col md={4}>
                      <Form.Control as="select" name="orderType" value={formData.orderType} onChange={handleOrderTypeChange} required>
                        <option value="receipt">Receipt</option>
                        <option value="invoice">Commercial Invoice</option>
                      </Form.Control>
                    </Col>
                  </Row>
                </Form.Group>
  
                {/* Personal Details */}
                <Row>
                  <Col>
                    <Form.Group controlId="fullName">
                      <Form.Label>First and Last Name (for shipping)*</Form.Label>
                      <Form.Control type="text" name="fullName" value={formData.fullName} onChange={handleChange} required />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="phoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                    </Form.Group>
                  </Col>
                </Row>
  
                <Row>
                  <Col>
                    <Form.Group controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="country">
                      <Form.Label>Country</Form.Label>
                      <Form.Control as="select" name="country" value={formData.country} onChange={handleChange} required>
                        <option value="">Select Country</option>
                        {countries.map((country) => (
                          <option key={country.code} value={country.name}>
                            {country.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
  
                {/* Address Details (Conditional) */}
                {formData.shippingOption !== 'Box Now' && (
                  <>
                    <Form.Group controlId="addressLine">
                      <Form.Label>Address Line</Form.Label>
                      <Form.Control type="text" name="addressLine" value={formData.addressLine} onChange={handleChange} required />
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group controlId="city">
                          <Form.Label>City</Form.Label>
                          <Form.Control type="text" name="city" value={formData.city} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="province">
                          <Form.Label>Province</Form.Label>
                          <Form.Control type="text" name="province" value={formData.province} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="zip">
                          <Form.Label>ZIP Code</Form.Label>
                          <Form.Control type="text" name="zip" value={formData.zip} onChange={handleChange} required />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
  
                {/* Invoice Fields (Conditional) */}
                {formData.orderType === 'invoice' && (
                  <>
                    <h4 className='checkout-order-title'>Billing Information</h4>
                    <Row>
                      <Col>
                        <Form.Group controlId="companyName">
                          <Form.Label>Company Name</Form.Label>
                          <Form.Control type="text" name="companyName" value={formData.companyName || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="vatNumber">
                          <Form.Label>VAT Number</Form.Label>
                          <Form.Control type="text" name="vatNumber" value={formData.vatNumber || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                    </Row>
  
                    <Row>
                      <Col>
                        <Form.Group controlId="companyAddress">
                          <Form.Label>Company Address</Form.Label>
                          <Form.Control type="text" name="companyAddress" value={formData.companyAddress || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="companyCity">
                          <Form.Label>Company City</Form.Label>
                          <Form.Control type="text" name="companyCity" value={formData.companyCity || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group controlId="companyZip">
                          <Form.Label>Company ZIP</Form.Label>
                          <Form.Control type="text" name="companyZip" value={formData.companyZip || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="occupation">
                          <Form.Label>Profession/Occupation</Form.Label>
                          <Form.Control type="text" name="occupation" value={formData.occupation || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="taxOffice">
                          <Form.Label>Tax Office</Form.Label>
                          <Form.Control type="text" name="taxOffice" value={formData.taxOffice || ''} onChange={handleChange} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </>
                )}
  
                {/* Shipping Options */}
                <Form.Group>
                  <Form.Label>Shipping Options</Form.Label>
                  <Row>
                    {formData.country === 'Greece' ? (
                      <Col>
                        <Form.Check
                          type="radio"
                          name="shippingOption"
                          id="elta"
                          label={
                            <Figure>
                              <Figure.Image width={171} height={180} alt="ELTA" src="/logos/elta-logo.png" />
                              <Figure.Caption>Delivery Cost: 4.35€</Figure.Caption>
                            </Figure>
                          }
                          value="ELTA"
                          checked={formData.shippingOption === 'ELTA'}
                          onChange={handleShippingOptionChange}
                          required
                        />
                      </Col>
                    ) : (
                      <Col>
                        <Form.Check
                          type="radio"
                          name="shippingOption"
                          id="fedex"
                          label={
                            <Figure>
                              <Figure.Image width={171} height={180} alt="FedEx" src="/logos/fedex-logo.png" />
                              <Figure.Caption>Delivery Cost: 25€</Figure.Caption>
                            </Figure>
                          }
                          value="FedEx"
                          checked={formData.shippingOption === 'FedEx'}
                          onChange={handleShippingOptionChange}
                          required
                        />
                      </Col>
                    )}
                  </Row>
                </Form.Group>
              </Col>
            </Row>
          </Col>
    
          {/* Right Section - Order Summary */}
          <Col className='checkout-right-cont'>
            <Container className="order-summary">
              <h4 className='order-summary-title'>Order Summary</h4>
              <div className="order-summary-details">
                <div className="order-summary-item">
                  <span>Product Quantity:</span>
                  <span>{cartItems.reduce((total, item) => total + item.quantity, 0)}</span>
                </div>
                <div className="order-summary-item">
                  <span>Amount:</span>
                  <span>{totalAmount.toFixed(2)}€</span>
                </div>
                <div className="order-summary-item">
                  <span>VAT (24% included):</span>
                  <span>{vatAmount.toFixed(2)}€</span>
                </div>
                <div className="order-summary-item">
                  <span>Shipping:</span>
                  <span>{shippingAmount.toFixed(2)}€</span>
                </div>
                <hr className='order-summary-divider'/>
                <div className="order-summary-item total">
                  <strong>Total Amount:</strong>
                  <strong>{(totalAmount + shippingAmount).toFixed(2)}€</strong>
                </div>
              </div>
    
              <hr />
              {/* Terms & Conditions */}
              <Form.Group controlId="termsCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={formData.agreedToTerms}
                  onChange={handleTermsChange}
                  required
                  label={
                    <>
                      I have read and agree to the{' '}
                      <a href="/terms-of-use" target="_blank" rel="noopener noreferrer">
                        Terms of Use
                      </a>.
                    </>
                  }
                />
              </Form.Group>
    
              {/* Submit Button */}
              <Button className="order-now-button" size="lg" type="submit">
                Order Now
              </Button>
            </Container>
          </Col>
        </Row>
      </Form>
    </Container>
  );  
}

// mapStateToProps function to get the required data from Redux store
const mapStateToProps = (state) => ({
  cartItems: state.items.cartItems,
  orderUUID: state.items.orderUUID,
});

const mapDispatchToProps = {
  resetCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
